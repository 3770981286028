import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["collapsibleTable", "toggleBtn", "showMoreBtn", "showLessBtn", "filterForm"]
    static values = { shown: Number }

    connect() {
        this.collapse();
    }

    submitForm() {
        this.filterFormTarget.querySelector("input[type='submit']").click();
    }

    toggleCollapse() {
        this.showMoreBtnTarget.classList.toggle("invisible")
        this.showLessBtnTarget.classList.toggle("invisible")

        if(this.collapsibleTableTarget.classList.contains('collapsed')) {
            this.expand();
        } else {
            this.collapse();
        }
    }

    collapse() {
        let tableIsCollapsed = ([...this.collapsibleTableTarget.children].map((columns) => { return columns.querySelector('.collapsible-items-container').children.length }).some(count => count > this.shownValue))
        if (! tableIsCollapsed) {
            this.toggleBtnTarget.classList.add("hidden")
        }
        this.collapsibleTableTarget.classList.add("collapsed");

        let columns = this.collapsibleTableTarget.children

        for (let i = 0; i < columns.length; i++) {
            this.collapseColumns(columns.item(i))
        }
    }

    expand() {
        this.collapsibleTableTarget.classList.remove("collapsed");
        let columns = this.collapsibleTableTarget.children

        for (let i = 0; i < columns.length; i++) {
            this.expandColumns(columns.item(i))
        }
    }

    collapseColumns(column) {
        let rows = column.querySelector('.collapsible-items-container').children

        for (let i = this.shownValue; i < rows.length; i++) {
            rows.item(i).classList.add("hidden")
        }
    }

    expandColumns(column) {
        let rows = column.querySelector('.collapsible-items-container').children

        for (let i = this.shownValue; i < rows.length; i++) {
            rows.item(i).classList.remove("hidden")
        }
    }

}
