import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "singleSlider", "stepMarkersContainer" ]

    connect() {
        this.slider = this.singleSliderTarget
        this.thumbHeight = this.slider.offsetHeight;
        this.sliderWidth = this.slider.offsetWidth;
        this.container = this.stepMarkersContainerTarget
        this.adjustSliderSize()
        this.generateStepMarkers()
    }

    adjustSliderSize() {
        const containerWidth = this.sliderWidth - (this.thumbHeight * 2) - 2;
        this.container.style.width = `${containerWidth}px`;
    }

    generateStepMarkers() {
        const max = parseInt(this.slider.max, 10);

        // Clear previous markers if any
        this.container.innerHTML = '';

        for (let i = 0; i < max; i++) {
            const marker = document.createElement('div');
            marker.classList.add('step-marker');

            // Calculate the left offset for the marker
            // For the first and last markers, the position should be adjusted by half the thumb's width
            const leftOffset = (this.thumbHeight / 2) + (i * (this.sliderWidth / (max - 1)));

            marker.style.left = `calc(${leftOffset}px - 5px)`; // Adjust the 5px if the marker's size is different

            // Append the marker to the container
            this.container.appendChild(marker);
        }
    }
}
