import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition";

export default class extends Controller {
    static targets = ["dropdownMenu", "selectButton", "arrowUp", "arrowDown"]

    connect() {
        document.addEventListener('click', this.outsideClick.bind(this))
    }

    toggleDropdown() {
        this.selected_style = this.selectButtonTarget.getAttribute('data-selected-style').split(' ')
        if(this.dropdownMenuTarget.classList.contains('hidden')) {
            this.showDropdown();
        } else {
            this.hideDropdown();
        }
    }

    showDropdown() {
        this.isSelected()
        this.dropdownMenuTarget.classList.remove("hidden");
        enter(this.dropdownMenuTarget);
    }

    hideDropdown() {
        this.isNotSelected()
        this.dropdownMenuTarget.classList.add('hidden')
        leave(this.dropdownMenuTarget)
        this.dispatch('closed')
    }

    outsideClick(event) {
        if (!this.element.contains(event.target) && !this.dropdownMenuTarget.classList.contains('hidden')) {
            this.hideDropdown();
        }
    }

    isSelected() {
        for (const style in this.selected_style) {
            this.selectButtonTarget.classList.add(this.selected_style[style])
        }
        this.toggleArrows()
    }

    isNotSelected() {
        for (const style in this.selected_style) {
            this.selectButtonTarget.classList.remove(this.selected_style[style])
        }
        this.toggleArrows()
    }

    toggleArrows() {
        if (this.hasArrowUpTarget && this.hasArrowDownTarget) {
            if (this.arrowUpTarget.classList.contains('hidden')) {
                this.arrowDownTarget.classList.add('hidden')
                this.arrowUpTarget.classList.remove('hidden')
            } else {
                this.arrowDownTarget.classList.remove('hidden')
                this.arrowUpTarget.classList.add('hidden')
            }
        }
    }
}
