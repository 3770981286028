import { Controller } from "@hotwired/stimulus"
import {get, put} from "@rails/request.js"

export default class extends Controller {
    static values = { scheduleId: Number }
    static targets = ["dots"]

    async onCardClick(event) {
        if (this.dotsTarget.contains(event.target)) {
            event.preventDefault();
            return;
        }

        await get(`/${window.currentWorkspaceSlug}/employee_zone/settings/processes/work_schedule/${this.scheduleIdValue}`, {
            responseKind: "turbo-stream"
        });
    }

    async updateSchedule(event, body) {
        event.preventDefault();
        await put(`/${window.currentWorkspaceSlug}/employee_zone/settings/processes/work_schedule/${this.scheduleIdValue}`, {
            responseKind: "turbo-stream",
            body: { schedule: body }
        });
    }

    async deactivateSchedule(event) {
        await this.updateSchedule(event, { active_state: 1 });
    }

    async activateSchedule(event) {
        await this.updateSchedule(event, { active_state: 0 });
    }

    async deleteSchedule(event) {
        await this.updateSchedule(event, { active_state: 2 });
    }

    async editSchedule() {
        await get(`/${window.currentWorkspaceSlug}/employee_zone/settings/processes/work_schedule/${this.scheduleIdValue}/edit`, {
            responseKind: "turbo-stream"
        });
    }
}
