import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["newChildrenWrapper", "newChildTemplate"]
    static values = {
        collectionName: String,
    }

    initialize () {
        this.wrapperSelector = `.nested-form-wrapper[data-collection-name="${this.collectionNameValue}"]`;
    }

    addChild (e) {
        e.preventDefault()

        const content = this.newChildTemplateTarget.innerHTML
            .replace(
                new RegExp(`NEW_RECORD_${this.collectionNameValue}_END`, 'g'),
                `NEW_RECORD_${this.timestampString()}_END`
            )

        this.newChildrenWrapperTarget.insertAdjacentHTML('beforebegin', content)
    }

    removeChild (e) {
        e.preventDefault()

        const wrapper = e.target.closest(this.wrapperSelector)

        if (wrapper.dataset.newRecord === 'true') {
            wrapper.remove()
        } else {
            wrapper.style.display = 'none'

            const input = wrapper.querySelector("input[name*='_destroy']")
            input.value = '1'
        }
    }

    timestampString () {
        return new Date().getTime().toString()
    }
}
