import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    submitForm() {
        let startDate = document.getElementById('employee_start_date')
        let endDate = document.getElementById('employee_end_date')
        let formContainer = document.getElementById('employee-zone--employees--show--time-entries--show--balance-card')

        if (startDate.value !== '' && endDate.value !== '') {
            formContainer.querySelector('form').requestSubmit()
        }
    }
}
