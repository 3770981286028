import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    selectItem(event){
        const selectEvent = new CustomEvent("itemSelected", {
            detail: { event },
            bubbles: true, // This makes the event bubble up through the DOM
        });

        // Dispatch the event on the scrollableDivTarget so it bubbles up through the DOM
        event.currentTarget.dispatchEvent(selectEvent);
    }
}
