import { Controller } from "@hotwired/stimulus"
import {get} from '@rails/request.js'

export default class extends Controller {
    static values = {workspaceSlug: String, employeeId: Number, contractId: Number, state: Number, salaryReductionType: Number }
    connect() {
        this.inputValue = document.body.querySelector('#contract_salary_reduction_option_id').value
    }
    async onChange(event){
        event.preventDefault();
        const contractSalaryReductionOptionId = document.body.querySelector('#contract_salary_reduction_option_id').value
        if(contractSalaryReductionOptionId !== this.inputValue) {
            await get(`/${this.workspaceSlugValue}/employee_zone/employees/${this.employeeIdValue}/contracts/salary/salary_reduction/${this.contractIdValue}/edit?contract_salary_reduction_option_id=${contractSalaryReductionOptionId}&contract_salary_reduction_type=${this.salaryReductionTypeValue}&state=${this.stateValue}`,  { responseKind: "turbo-stream" })
            this.inputValue = contractSalaryReductionOptionId
        }
    }
}