import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    onInput() {
        const checkin = this.getDateTimeValue("#visible_check_in_date_input", "#time_entry_check_in_time")
        const checkout = this.getDateTimeValue("#visible_check_out_date_input", "#time_entry_check_out_time")
        this.initializeSummaryInputs()
        if (checkin && checkout && checkin < checkout) {
            const totalShiftSeconds = (checkout - checkin) / 1000

            const breaks = this.getBreaks(checkin, checkout)
            const totalBreakSeconds = breaks.reduce((total, currentBreak) => {
                return total + (currentBreak.end - currentBreak.start) / 1000
            }, 0)

            const totalWorkSeconds = totalShiftSeconds - totalBreakSeconds

            this.displayTime(this.shiftHours, this.shiftMinutes, totalShiftSeconds)
            this.displayTime(this.breakHours, this.breakMinutes, totalBreakSeconds)
            this.displayTime(this.workHours, this.workMinutes, totalWorkSeconds)
        } else {
            this.displayTime(this.shiftHours, this.shiftMinutes, 0)
            this.displayTime(this.breakHours, this.breakMinutes, 0)
            this.displayTime(this.workHours, this.workMinutes, 0)
        }
    }

    initializeSummaryInputs() {
        this.shiftHours = document.getElementById('employee-zone--time-entries--new-shift-hours')
        this.shiftMinutes = document.getElementById('employee-zone--time-entries--new-shift-minutes')

        this.breakHours = document.getElementById('employee-zone--time-entries--new-break-hours')
        this.breakMinutes = document.getElementById('employee-zone--time-entries--new-break-minutes')

        this.workHours = document.getElementById('employee-zone--time-entries--new-work-hours')
        this.workMinutes = document.getElementById('employee-zone--time-entries--new-work-minutes')
    }

    getDateTimeValue(dateSelector, timeSelector) {
        const dateInput = document.querySelector(dateSelector)
        const timeInput = document.querySelector(timeSelector)

        if (dateInput && timeInput && dateInput.value && timeInput.value) {
            const dateParts = dateInput.value.split(".")
            const day = parseInt(dateParts[0], 10)
            const month = parseInt(dateParts[1], 10) - 1 // Months are zero-based in JavaScript
            const year = parseInt(dateParts[2], 10)

            const timeParts = timeInput.value.split(":")
            const hours = parseInt(timeParts[0], 10)
            const minutes = parseInt(timeParts[1], 10)

            const date = new Date(year, month, day, hours, minutes)
            return date
        }

        return null
    }


    getBreaks(checkin, checkout) {
        const parseTime = (value) => {
            const date = new Date(checkin)
            const time = value.split(":")
            date.setHours(time[0])
            date.setMinutes(time[1])
            date.setSeconds(time[2] ? time[2] : 0) // Handle both "HH:MM" and "HH:MM:SS" formats
            return date
        }

        const sameDay = checkin.toDateString() === checkout.toDateString()

        const breaks = Array.from(document.getElementById('time-entry-breaks').querySelectorAll('.time-range')).map(input => {
            const [startInput, endInput] = input.querySelectorAll('input.hidden')
            const start = startInput.value ? parseTime(startInput.value) : null
            const end = endInput.value ? parseTime(endInput.value) : null
            return { start, end }
        })

        return breaks.filter(breakTime =>
            breakTime.start && breakTime.end &&
            (!sameDay || (breakTime.start >= checkin && breakTime.end <= checkout))
        )
    }

    displayTime(hoursTarget, minutesTarget, totalSeconds) {
        const hours = Math.floor(totalSeconds / 3600)
        const minutes = Math.floor((totalSeconds % 3600) / 60)

        hoursTarget.innerText = hours
        minutesTarget.innerText = minutes
    }

}