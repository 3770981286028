import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["submitBtn"]
  submitForm(){
    this.submitBtnTarget.click()
  }

}
