import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input'
import countryCodeEmoji from 'country-code-emoji'

export default class extends Controller {
    static values = {hasErrors: Boolean}
    static targets = [ 'email' ]

    connect() {
        this.clickOutsideListener = this.handleClickOutside.bind(this)
        window.addEventListener("click", this.clickOutsideListener);

        if (this.hasErrorsValue) {
            this.addStyle();
        }
    }


    handleClickOutside(event) {

        if(document.activeElement !== this.emailTarget ) {
            this.removeStyle()
        }
    }

    addStyle() {
        let ringColor
        let focusRing
        if (this.hasErrorsValue) {
            this.emailTarget.classList.remove('focus:ring-primary')
            focusRing = 'focus:ring-red'
            ringColor = 'ring-red'
        } else {
            focusRing = 'focus:ring-primary'
            ringColor = 'ring-primary'
        }
        this.emailTarget.classList.add('ring-2', ringColor, focusRing )
    }

    removeStyle() {
        if (!this.hasErrorsValue) {
            this.emailTarget.classList.remove('ring-2', 'ring-primary')
        }
    }


    onClick() {
        this.addStyle()
    }

    disconnect() {
        document.removeEventListener("click", this.clickOutsideListener);
        super.disconnect()
    }
}
