import { Controller } from "@hotwired/stimulus"
import {get} from "@rails/request.js"


export default class extends Controller {
    static values = { contractId: Number, employeeId: Number }
    static targets = ["dots"]

    async onCardClick(event) {
        if (this.dotsTarget.contains(event.target)) {
            event.preventDefault();
            return;
        }

        await get(`/${window.currentWorkspaceSlug}/employee_zone/employees/${this.employeeIdValue}/contracts/${this.contractIdValue}`, {
            responseKind: "turbo-stream"
        });
    }
    exportPdf() {
        // TODO: implement pdfExport
    }

    sendByMail() {
        // TODO: implement sendByMail
    }

    uploadContract() {
        // TODO: implement contractUpload
    }

    terminateContract() {
        // TODO: implement contract Termination
    }

    cancelSigningProcess() {
        // TODO: implement cancelSigningProcess
    }
}