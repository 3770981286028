import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["contentContainer", "arrowUp", "arrowDown"];
    static values = {isTogglable : Boolean}

    connect() {
        if(!this.isTogglableValue) {
            this.arrowUpTarget.classList.add('hidden')
        }
    }


    onClick() {
        if(this.isTogglableValue) {
            this.contentContainerTarget.classList.toggle('hidden')
            this.arrowUpTarget.classList.toggle('hidden')
            this.arrowDownTarget.classList.toggle('hidden')
        }
    }
}