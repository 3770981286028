import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["target", "template"]
    static values = {
        wrapperSelector: String,
        actionSelector: String
    }

    initialize () {
        this.wrapperSelector = this.wrapperSelectorValue || '.nested-form-wrapper'
        this.actionSelector = this.actionSelectorValue || '.nested-form-action'
    }

    add (e) {
        e.preventDefault()

        const actionSelector = e.target.closest(this.actionSelector)
        const stringifyTimestamp = new Date().getTime().toString()

        let target
        let templateTarget

        if (actionSelector) {
            const model = actionSelector.dataset.model
            templateTarget = this.templateTargets.find(el => el.dataset.modelTemplate === model)
            target = this.targetTargets.find(el => el.dataset.modelTarget === model)
        } else {
            target = this.targetTarget
            templateTarget = this.templateTarget
        }

        const content = templateTarget.innerHTML.replace(/NEW_RECORD/g, `NEW_RECORD_${stringifyTimestamp}`)
        target.insertAdjacentHTML('beforebegin', content)
    }

    remove (e) {
        e.preventDefault()

        // @ts-ignore
        const wrapper = e.target.closest(this.wrapperSelector)

        if (wrapper.dataset.newRecord === 'true') {
            wrapper.remove()
        } else {
            wrapper.style.display = 'none'

            const input = wrapper.querySelector("input[name*='_destroy']")
            input.value = '1'
        }
    }
}
