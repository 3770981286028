import ParentController from "~/controllers/components/element/dropdown_controller"

export default class extends ParentController{
    showDropdown() {
        this.dropdownMenuTarget.closest('.fc-timeline-event-harness').style.zIndex = "50"
        super.showDropdown()
    }

    hideDropdown() {
        this.dropdownMenuTarget.closest('.fc-timeline-event-harness').style.zIndex = "0"
        super.hideDropdown()
    }
}
