import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "selectedRange", "rangeInputs", "textInputs" ]

    connect() {
        this.minRange = this.element.getAttribute('data-min-range')
        this.range = this.selectedRangeTarget
        this.rangeInputs = this.rangeInputsTarget.children
        if(this.hasTextInputsTarget) {
            this.textInputs = this.textInputsTarget
        }
        this.onRangeInput()
    }

    onRangeInput(event) {
        let minRange = parseInt(this.rangeInputs[0].value);
        let maxRange = parseInt(this.rangeInputs[1].value);
        if (maxRange - minRange < this.minRange) {
            if (event.currentTarget.classList.contains('min')) {
                this.rangeInputs[0].value = maxRange - parseInt(this.minRange);
                this.rangeInputs[1].value = maxRange;
            } else {
                this.rangeInputs[1].value = minRange + parseInt(this.minRange);
            }
        } else {
            if(this.hasTextInputsTarget) {
                this.textInputs[0].value = minRange;
                this.textInputs[1].value = maxRange;
            }
            this.range.style.left = (minRange /this.rangeInputs[0].max) * 100 + "%";
            this.range.style.right = 100 - (maxRange / this.rangeInputs[1].max) * 100 + "%";
        }
        this.updateSliderStyle()
    }

    updateSliderStyle() {
        let minRange = parseInt(this.rangeInputs[0].value);
        let maxRange = parseInt(this.rangeInputs[1].value);
        this.range.style.left = (minRange /this.rangeInputs[0].max) * 100 + "%";
        this.range.style.right = 100 - (maxRange / this.rangeInputs[1].max) * 100 + "%";
    }

    onTextInput(event) {
        let minPrice = this.textInputs[0].value;
        let maxPrice = this.textInputs[1].value;
        if (maxPrice - minPrice >= this.minRange && maxPrice <= this.rangeInputs[1].max) {
            if (event.currentTarget.classList.contains('min')) {
                this.rangeInputs[0].value = minPrice;
                this.range.style.left = (minPrice / this.rangeInputs[0].max) * 100 + "%";
            } else {
                this.rangeInputs[1].value = maxPrice;
                this.range.style.right = 100 - (maxPrice / this.rangeInputs[1].max) * 100 + "%";
            }
        }
    }


}
