import Mustache from 'mustache'

export default class MustacheHelper {
    static toElement(templateId, placeholders = {}, styles = []) {
        const wrapper = document.createElement('div')
        wrapper.classList.add('wrapper')
        const template = document.getElementById(templateId).innerHTML
        wrapper.innerHTML = Mustache.render(template, placeholders)

        styles.forEach(style => {
            wrapper.querySelectorAll(style.target).forEach(element => {
                element.style[style.style] = style.value
            })
        })

        return wrapper;
    }
}
