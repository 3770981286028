import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import luxonPlugin from '@fullcalendar/luxon3'
import { DateTime } from 'luxon'
import camelcaseKeys from 'camelcase-keys'
import { get } from '@rails/request.js'
import MustacheHelper from "../../../../helpers/mustache_helper"

export default class extends Controller {
    static targets = ['calendarWrapper']

    connect() {
        this.setupCalendar();
        this.setupDatepicker();
        this.calendar.render();
    }

    eventReloaderTargetConnected(_eventReloader) {
        this.reloadEvents()
    }

    setupCalendar = () => {
        this.calendar = new Calendar(this.calendarWrapperTarget, {
            ...this.calendarBaseOptions(),
            ...this.calendarEventOptions(),
            ...this.calendarHeaderOptions(),
        })
    }

    setupDatepicker = () => {
        const datepickerWrapper = document.getElementById('calendar-datepicker-wrapper')
        const datepickerInput = datepickerWrapper.querySelector('input')
        datepickerInput.addEventListener('change', (event) => {
            const date = DateTime.fromFormat(event.target.value, 'dd.MM.yyyy')
            this.calendar.gotoDate(date.toISODate())
        })
    }

    calendarBaseOptions = () => ({
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',

        plugins: [interactionPlugin, luxonPlugin, timeGridPlugin],

        initialView: 'timeGridWeek',
        initialDate: DateTime.now().startOf('week').toISODate(),
        duration: { days: 7 },
        slotDuration: { hours: 1 },
        firstDay: 1, // Start the week on monday

        headerToolbar: false,

        contentHeight: 'auto',

        // TODO handle scrolling better
        stickyHeaderDates: true,
    })

    calendarEventOptions = () => ({
        eventSources: [
            {
                id: 'shifts',
                url: `/${window.currentWorkspaceSlug}/employee_zone/calendar/shifts.json`,
            },
            {
                id: 'absences',
                url: `/${window.currentWorkspaceSlug}/employee_zone/calendar/absences.json`,
            },
            {
                id: 'calendar_entries',
                url: `/${window.currentWorkspaceSlug}/employee_zone/calendar/calendar_entries.json`,
            }
        ],
        eventDataTransform: function (eventData) {
            return camelcaseKeys(eventData, { deep: true })
        },

        eventClassNames: 'bg-transparent border-0',

        eventContent: (eventArgument) => {
            const templateValues = eventArgument.event.extendedProps?.templateValues
            if (!templateValues) {
                return { domNodes: [] }
            } else if (templateValues.entryType === 'shift') {
                return this.shiftElement(templateValues);
            } else if (templateValues.entryType === 'absence') {
                return this.absenceElement(templateValues);
            } else if (['meeting', 'event', 'internal_event', 'task'].includes(templateValues.entryType)) {
                return this.calendarEntryElement(templateValues);
            }
        },

        eventClick: async function (argument) {
            const calendarEntryId = argument.event.id.replace(/\D/g, '')
            await get(`/${window.currentWorkspaceSlug}/employee_zone/calendar/calendar_entries/${calendarEntryId}`, { responseKind: "turbo-stream" })
        }
    })

    calendarHeaderOptions = () => ({
        dayHeaderContent: (arg) => {
            const templateId = 'components--employee-zone--calendar--index--day-header'
            const selectedDateTime = DateTime.fromJSDate(arg.date)
            const currentDateTime = DateTime.fromJSDate(new Date())
            const placeholders = {
                dayOfMonth: selectedDateTime.toFormat('dd'),
                dayOfWeek: selectedDateTime.toFormat('ccc'),
                isCurrentDay: selectedDateTime.hasSame(currentDateTime, 'day')
            }

            return { domNodes: [MustacheHelper.toElement(templateId, placeholders)] }
        },
        slotLabelContent: (arg) => {
            const templateId = 'components--employee-zone--calendar--index--slot-label'
            const selectedDateTime = DateTime.fromJSDate(arg.date)
            const placeholders = {
                // TODO: I did a quick fix to not show the hour when it's 00:00
                // It's tricky to display it above the other content (z-index seems not to work)
                time: selectedDateTime.hour !== 0 ? selectedDateTime.toFormat('HH:mm') : '',
            }

            return { domNodes: [MustacheHelper.toElement(templateId, placeholders)] }
        },
        allDayContent: false
    })

    shiftElement = (templateValues) => {
        const templateId = 'components--employee-zone--calendar--index--shift'

        const shiftStartTime = this.formatTime(templateValues.shiftStart)
        const shiftEndTime = this.formatTime(templateValues.shiftEnd)
        templateValues.shiftRange = `${shiftStartTime} - ${shiftEndTime}`
        templateValues.durationHours = (templateValues.durationSeconds / 3600).toFixed(2)

        templateValues.breakRanges = templateValues.shiftBreaks.map((shiftBreak, index, shiftBreaks) => {
            const breakStartTime = this.formatTime(shiftBreak.breakStart)
            const breakEndTime = this.formatTime(shiftBreak.breakEnd)
            let breakRange = `${breakStartTime} - ${breakEndTime}`
            if (index !== shiftBreaks.length - 1) {
                breakRange += ', '
            }
            return breakRange;
        })

        templateValues.publishStateClass = templateValues.published ? 'published' : 'unpublished'

        const placeholders = { ...templateValues }
        const styles = [
            {
                target: '.background-color-target',
                style: 'backgroundColor',
                value: templateValues.backgroundColor
            },
            {
                target: '.published-background-color-target',
                style: 'backgroundColor',
                value: templateValues.published ? templateValues.publishedBackgroundColor : 'white'
            }
        ]

        return { domNodes: [MustacheHelper.toElement(templateId, placeholders, styles)] }
    }

    absenceElement = (templateValues) => {
        const templateId = 'components--employee-zone--calendar--index--absence'
        templateValues.publishedBackgroundClass = templateValues.published ? 'bg-canvas-darker' : 'bg-white'
        templateValues.publishStateClass = templateValues.published ? 'published' : 'unpublished'
        const placeholders = { ...templateValues }
        const styles = []
        const absenceFragment = MustacheHelper.toElement(templateId, placeholders, styles)
        const iconTemplateId = `components--employee-zone--calendar--index--absence-icon-${templateValues.iconName}`
        absenceFragment.querySelector('.icon-wrapper').innerHTML = document.getElementById(iconTemplateId).innerHTML
        return { domNodes: [absenceFragment] }
    }

    calendarEntryElement = (templateValues) => {
        const templateId = 'components--employee-zone--calendar--index--calendar-entry'
        templateValues.isTask = templateValues.entryType === 'task'
        templateValues.acceptedBackgroundClass = (templateValues.participationState === 'accepted' || templateValues.isTask) ? 'background-color-target' : 'bg-white'
        templateValues.acceptanceStateClass = templateValues.participationState
        templateValues.textColorClass = ['bg-blue', 'bg-green'].includes(templateValues.acceptedBackgroundClass) ? 'text-canvas-white' : 'text-ink'
        const calendarEntryStartTime = this.formatTime(templateValues.startTime)
        const calendarEntryEndTime = this.formatTime(templateValues.endTime)
        templateValues.calendarEntryRange = `${calendarEntryStartTime} - ${calendarEntryEndTime}`
        const placeholders = { ...templateValues }
        const styles = [
            {
                target: '.background-color-target',
                style: 'backgroundColor',
                value: templateValues.backgroundColorHex
            },
            {
                target: '.text-color-target',
                style: 'color',
                value: templateValues.textColorHex
            },
        ]

        const calendarEntryFragment = MustacheHelper.toElement(templateId, placeholders, styles)
        return { domNodes: [calendarEntryFragment] }
    }

    formatTime = (time) => (DateTime.fromObject({
        hour: time.hour,
        minute: time.minute
    }).toLocaleString(DateTime.TIME_24_SIMPLE))

    reloadEvents = (event) => {
        this.calendar.refetchEvents()
    }
}
