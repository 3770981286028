
import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["primary", "secondary"]

  connect() {
    if (this.hasPrimaryTarget) {
      this.primaryTarget.classList.remove("hidden");
    }
    if (this.hasSecondaryTarget) {
      this.secondaryTarget.classList.add("hidden");
    }
  }

  toggle(event) {
    const element = event.currentTarget
    const hideSelector = element.dataset.hideTargetSelector
    const showSelector = element.dataset.showTargetSelector
    document.querySelector(hideSelector).classList.add("hidden");
    document.querySelector(showSelector).classList.remove("hidden"); 

    if (this.hasPrimaryTarget) {
      this.primaryTarget.classList.toggle("hidden");
    }
    if (this.hasSecondaryTarget) {
      this.secondaryTarget.classList.toggle("hidden");
    }
  }
}
