import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'hourInput', 'minuteInput', 'hiddenInput' ]
  connect() {
    this.prefillInputs()
  }

  prefillInputs() {
   const inputValue = this.hiddenInputTarget.value
   const [hour, minute] = inputValue.split(':')

    if (!!hour) {
      this.hourInputTarget.value = hour
    }

    if (!!minute) {
      this.minuteInputTarget.value = minute
    }
  }
  updateTime(inputEvent) {
    let hourValue = this.hourInputTarget.value
    let minuteValue = this.minuteInputTarget.value

    if (minuteValue === null && minuteValue.trim() === '') {
      minuteValue = '00'
    }

    const fullValue = `${hourValue}:${minuteValue}`
    this.hiddenInputTarget.value = fullValue
  }
}
