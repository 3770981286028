import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {link : String}
  static targets = ["container"];

  submitForm(event) {
    event.preventDefault(); // Prevent the default form submission

    // Find the first form inside the target container
    const firstForm = this.containerTarget.querySelector('form');
    if (!firstForm) return; // If no form is found, exit the function

    firstForm.requestSubmit()
  }
}