import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["subItemsContainer"]

    connect() {
        this.subItemsContainerTargets.forEach(container => {
            const groupId = container.getAttribute('data-group-id')
            this.loadState(container, groupId);
        });
    }

    toggleSubItems(event) {
        const groupId = this.subItemsContainerTarget.getAttribute('data-group-id')
        const container = this.subItemsContainerTargets.find(c => c.getAttribute('data-group-id') === groupId);
        container.classList.toggle('hidden');
        this.saveState(container, groupId);
    }

    saveState(container, groupId) {
        const isOpen = !container.classList.contains('hidden');
        localStorage.setItem(`subItemsState-${groupId}`, isOpen);
    }

    loadState(container, groupId) {
        const isOpen = localStorage.getItem(`subItemsState-${groupId}`) === 'true';
        if (isOpen) {
            container.classList.remove('hidden');
        } else {
            container.classList.add('hidden');
        }
    }
}
