import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import luxonPlugin from '@fullcalendar/luxon3'
import { DateTime } from 'luxon'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import camelcaseKeys from 'camelcase-keys'
import decamelizeKeys from 'decamelize-keys'
import { get, post } from '@rails/request.js'
import MustacheHelper from "../../../../helpers/mustache_helper"

export default class extends Controller {
    static targets = ['timelineWrapper', 'eventReloader']
    connect() {
        this.setupCalendar();
        this.setupDraggableItems();
        this.setupDatepicker();
        this.calendar.render();
    }

    eventReloaderTargetConnected(_eventReloader) {
        this.reloadEvents()
    }

    setupCalendar = () => {
        this.calendar = new Calendar(this.timelineWrapperTarget, {
            ...this.calendarBaseOptions(),
            ...this.calendarResourceOptions(),
            ...this.calendarEventOptions(),
            ...this.calendarHeaderOptions(),
            ...this.calendarDragAndDropOptions(),
        })
    }

    setupDraggableItems = () => {
        new Draggable(document.getElementById('shift-and-absence-template-list'), {
            itemSelector: '.draggable-item',
        })
    }

    setupDatepicker = () => {
        const datepickerWrapper = document.getElementById('work-schedule-datepicker-wrapper')
        const datepickerInput = datepickerWrapper.querySelector('input')
        datepickerInput.addEventListener('change', (event) => {
            const date = DateTime.fromFormat(event.target.value, 'dd.MM.yyyy')
            this.calendar.gotoDate(date.toISODate())
        })
    }

    calendarBaseOptions = () => ({
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',

        plugins: [interactionPlugin, luxonPlugin, resourceTimelinePlugin],

        initialView: 'resourceTimeline',
        initialDate: DateTime.now().startOf('week').toISODate(),
        duration: { days: 7 },
        slotDuration: { days: 1 },

        headerToolbar: false,

        contentHeight: 'auto',

        // TODO handle scrolling after the sticky footer has been created
        stickyHeaderDates: true,
    })

    calendarResourceOptions = () => ({
        resources: async function (fetchInfo, successCallback, failureCallback) {
            const fetchResponse = await get(`/${window.currentWorkspaceSlug}/employee_zone/work_schedule/contract_functions.json`)
            if (fetchResponse.ok) {
                const data = await fetchResponse.response.json()
                const camelCasedData = camelcaseKeys(data, { deep: true })
                successCallback(camelCasedData)
            }
        },

        // I didn't find a way yet to make this dynamic
        resourceAreaWidth: '15rem',

        resourceLabelContent: (resourceArgument) => {
            const templateId = 'components--employee-zone--work-schedule--index--job-position'
            const templateValues = resourceArgument.resource.extendedProps.templateValues
            const nameParts = templateValues.employeeName.split(' ')
            templateValues.initials = nameParts.map(namePart => namePart[0]).join('')
            const placeholders = { ...templateValues }
            const styles = [
                {
                    target: '.job-position-headliner-badge',
                    style: 'backgroundColor',
                    value: templateValues.badgeBackgroundColor
                },
                {
                    target: '.job-position-headliner-badge',
                    style: 'color',
                    value: templateValues.badgeTextColor
                },
                {
                    target: '.initials-background',
                    style: 'backgroundColor',
                    value: templateValues.badgeTextColor
                }
            ]
            return { domNodes: [MustacheHelper.toElement(templateId, placeholders, styles)] }
        },
    })

    calendarEventOptions = () => ({
        eventSources: [
            {
                id: 'shifts',
                url: `/${window.currentWorkspaceSlug}/employee_zone/work_schedule/shifts.json`,
            }, {
                id: 'absences',
                url: `/${window.currentWorkspaceSlug}/employee_zone/work_schedule/absences.json`,
            }
        ],
        eventDataTransform: function (eventData) {
            return camelcaseKeys(eventData, { deep: true })
        },

        eventClassNames: 'bg-transparent border-0',

        eventContent: (eventArgument) => {
            const templateValues = eventArgument.event.extendedProps?.templateValues
            if (!templateValues) {
                return { domNodes: [] }
            } else if (templateValues.entryType === 'shift') {
                return this.shiftElement(templateValues);
            } else if (templateValues.entryType === 'absence') {
                return this.absenceElement(templateValues);
            }
        },

        eventClick: function (info) {
            // TODO handle event clicks
        },
    })

    calendarHeaderOptions = () => ({
        slotLabelContent: (arg) => {
            const templateId = 'components--employee-zone--work-schedule--index--day-header'
            const placeholders = {
                date: DateTime.fromJSDate(arg.date).toFormat('dd.MM.yy'),
                dayOfWeek: DateTime.fromJSDate(arg.date).toFormat('ccc')
            }

            return { domNodes: [MustacheHelper.toElement(templateId, placeholders)] }
        },

        resourceAreaHeaderContent: (arg) => {
            const templateId = 'components--employee-zone--work-schedule--index--total-hours'
            return { domNodes: [MustacheHelper.toElement(templateId)] }
        },
    })

    calendarDragAndDropOptions = () => ({
        droppable: true,
        drop: async (eventData) => {
            const templateData = camelcaseKeys(JSON.parse(eventData.draggedEl.dataset.calendarEntry), { deep: true })

            let calendarEntryType
            if (templateData.templateType === "shift")
                calendarEntryType = "shifts"
            else if (templateData.templateType === "absence")
                calendarEntryType = "absences"
            else {
                throw new Error('Unknown template type')
            }

            const requestBody = {
                date: eventData.dateStr,
                contractFunctionId: eventData.resource.id,
                templateId: templateData.templateId
            }

            const snakeCaseRequestBody = decamelizeKeys(requestBody, { deep: true })

            const requestPath = `/${window.currentWorkspaceSlug}/employee_zone/work_schedule/${calendarEntryType}.json`
            const fetchResponse = await post(requestPath, { contentType: "application/json", body: snakeCaseRequestBody })
            if (fetchResponse.ok) {
                const calendarEntryData = await fetchResponse.response.json()
                const camelCasedCalendarEntryData = camelcaseKeys(calendarEntryData, { deep: true })
                this.calendar.refetchEvents()
            }
        },
    })

    shiftElement = (templateValues) => {
        const templateId = 'components--employee-zone--work-schedule--index--shift'

        const shiftStartTime = this.formatTime(templateValues.shiftStart)
        const shiftEndTime = this.formatTime(templateValues.shiftEnd)
        templateValues.shiftRange = `${shiftStartTime} - ${shiftEndTime}`
        templateValues.durationHours = (templateValues.durationSeconds / 3600).toFixed(2)

        templateValues.breakRanges = templateValues.shiftBreaks.map((shiftBreak, index, shiftBreaks) => {
            const breakStartTime = this.formatTime(shiftBreak.breakStart)
            const breakEndTime = this.formatTime(shiftBreak.breakEnd)
            let breakRange = `${breakStartTime} - ${breakEndTime}`
            if (index !== shiftBreaks.length - 1) {
                breakRange += ', '
            }
            return breakRange;
        })

        templateValues.publishStateClass = templateValues.published ? 'published' : 'unpublished'

        const placeholders = { ...templateValues }
        const styles = [
            {
                target: '.background-color-target',
                style: 'backgroundColor',
                value: templateValues.backgroundColor
            },
            {
                target: '.published-background-color-target',
                style: 'backgroundColor',
                value: templateValues.published ? templateValues.publishedBackgroundColor : 'white'
            }
        ]

        return { domNodes: [MustacheHelper.toElement(templateId, placeholders, styles)] }
    }

    absenceElement = (templateValues) => {
        const templateId = 'components--employee-zone--work-schedule--index--absence'
        templateValues.publishedBackgroundClass = templateValues.published ? 'bg-canvas-darker' : 'bg-white'
        templateValues.publishStateClass = templateValues.published ? 'published' : 'unpublished'
        const placeholders = { ...templateValues }
        const styles = []
        const absenceFragment = MustacheHelper.toElement(templateId, placeholders, styles)
        const iconTemplateId = `components--employee-zone--work-schedule--index--absence-icon-${templateValues.iconName}`
        absenceFragment.querySelector('.icon-wrapper').innerHTML = document.getElementById(iconTemplateId).innerHTML
        return { domNodes: [absenceFragment] }
    }

    formatTime = (time) => (DateTime.fromObject({
        hour: time.hour,
        minute: time.minute
    }).toLocaleString(DateTime.TIME_24_SIMPLE))

    reloadEvents = (event) => {
        this.calendar.refetchEvents()
    }
}
