import { Controller } from "@hotwired/stimulus"
import {get} from '@rails/request.js'

export default class extends Controller {
    static targets = ["grossWageDiv", "totalDeductionsDiv", "totalAllowancesDiv", "totalNetSalaryDiv"]
    static values = { isMonthlyPayed: Boolean, workspaceSlug: String, employeeId: Number, contractId: Number, state: Number, defaultWorkingHours: Number }
    connect() {
        this.inputValue = document.body.querySelector('#contract_salary_reduction_type').value
        this.onInput()
    }
    async onChange(event){
        event.preventDefault();
        const contractSalaryReductionType = document.body.querySelector('#contract_salary_reduction_type').value
        if(contractSalaryReductionType !== this.inputValue) {
            await get(`/${this.workspaceSlugValue}/employee_zone/employees/${this.employeeIdValue}/contracts/salary/salary_reduction/${this.contractIdValue}/edit?contract_salary_reduction_type=${contractSalaryReductionType}&state=${this.stateValue}`,  { responseKind: "turbo-stream" })
            this.inputValue = document.body.querySelector('#contract_salary_reduction_type').value
        }
    }

    onInput() {
        if(this.hasGrossWageDivTarget) {
            this.calculateGrossWage()
            this.calculateSalaryReductions()
            this.calculateSalaryAllowances()
            this.calculateSalaryDeductions()
            this.calculateTotalNetSalary()
        }
    }

    calculateGrossWage() {
        const turnoverSalaryElement = document.querySelector('#contract_turnover_salary')
        const thirteenthSalaryElement = document.querySelector('#contract_thirteenth_salary_percentage')
        const customSalaries = document.querySelectorAll("[name*='contract_additional_salaries_attributes'][name*='value']");
        let thirteenth_salary_type = thirteenthSalaryElement.closest('[data-salary-type-id-value]').getAttribute('data-salary-type-id-value')

        const turnoverSalary = turnoverSalaryElement && !isNaN(Number(turnoverSalaryElement.value)) ? Number(turnoverSalaryElement.value) : 0
        let thirteenthSalary = thirteenthSalaryElement && !isNaN(Number(thirteenthSalaryElement.value)) ? Number(thirteenthSalaryElement.value) : 0
        // check if the salary has the same salary type as thirteenthSalary
        thirteenthSalary = this.convertValue(thirteenthSalary, thirteenth_salary_type)

        const totalAdditionalSalaries = Array.from(customSalaries).reduce((sum, element) => {
            let salary_type = element.closest('[data-salary-type-id-value]').getAttribute('data-salary-type-id-value')
            let salary_unit = element.closest('[data-salary-unit-id-value]').getAttribute('data-salary-unit-id-value')
            let value = element.value ? Number(element.value) : 0; // Convert to number, defaulting to 0 if conversion fails or if null

            // check if its a percent of the salary
            if (Number(salary_unit) !== 1) {
                value = turnoverSalary * (value / 100)
            }

            // check if the salary has the same salary type as the custom salary
            value = this.convertValue(value, salary_type)
            return sum + (!isNaN(value) ? value : 0); // Add to sum, skip NaN values by adding 0 instead
        }, 0);


        const thirteenthSalaryPerMonth = turnoverSalary * (thirteenthSalary / 100)

        let gross_wage = this.formatNumber(turnoverSalary + thirteenthSalaryPerMonth + totalAdditionalSalaries)
        if(this.grossWageDivTarget.innerText != gross_wage) {
            this.sendRequest(turnoverSalary + thirteenthSalaryPerMonth + totalAdditionalSalaries)
        }
        this.grossWageDivTarget.innerText = gross_wage
    }

    convertValue(value, salary_type) {
        if ((this.isMonthlyPayedValue && Number(salary_type) === 1) || (!this.isMonthlyPayedValue && Number(salary_type) === 2)) {
            return value
        } else {
            return 0
        }
    }

    async sendRequest(gross_wage) {
        await get(`/${this.workspaceSlugValue}/employee_zone/employees/${this.employeeIdValue}/contracts/salary/salary_deduction/${this.contractIdValue}/edit?gross_wage=${gross_wage}`,  { responseKind: "turbo-stream" })
    }
    calculateSalaryReductions() {

    }

    calculateSalaryAllowances() {
        const elements = document.querySelectorAll('[name*="contract_salary_allowances_attributes"][name*="value"]');
        const grossWage = Number(this.convertToNumber(this.grossWageDivTarget.innerText))
        let total = 0
        elements.forEach(element => {
            if(element.getAttribute('overlay_label') == 'percentage') {
                total += grossWage * ((!isNaN(Number(element.value)) ? Number(element.value) : 0) / 100)
            } else {
                total += !isNaN(Number(element.value)) ? Number(element.value) : 0
            }
        });

        this.totalAllowancesDivTarget.innerText = this.formatNumber(total)
    }

    calculateSalaryDeductions() {
        const elements = document.querySelectorAll('[name*="contract_salary_deductions_attributes"][name*="value"]');
        const grossWage = Number(this.convertToNumber(this.grossWageDivTarget.innerText))
        let total = 0
        elements.forEach(element => {
            if(element.getAttribute('overlay_label') == 'percentage') {
                total += grossWage * ((!isNaN(Number(element.value)) ? Number(element.value) : 0) / 100)
            } else {
                total += !isNaN(Number(element.value)) ? Number(element.value) : 0
            }
        });
        this.totalDeductionsDivTarget.innerText = this.formatNumber(total)
    }

    calculateTotalNetSalary() {
        const grossSalary = Number(this.convertToNumber(this.grossWageDivTarget.innerText))
        const salaryDeduction = Number(this.convertToNumber(this.totalDeductionsDivTarget.innerText))
        const salaryAllowances = Number(this.convertToNumber(this.totalAllowancesDivTarget.innerText))

        this.totalNetSalaryDivTarget.innerText = this.formatNumber(grossSalary + salaryAllowances - salaryDeduction)
    }

    formatNumber(number) {
        // Round the number to two decimal places
        const roundedNumber = Math.round(number * 100) / 100;

        // Convert the rounded number to a string and split into integer and decimal parts
        const [integerPart, decimalPart] = roundedNumber.toFixed(2).split('.');

        // Format the integer part using the 'fr-CH' locale for space as a thousand separator
        const formattedIntegerPart = new Intl.NumberFormat('fr-CH').format(parseInt(integerPart));

        // Replace spaces with apostrophes in the integer part
        const integerWithApostrophes = formattedIntegerPart.replace(/\s/g, "'");

        // Concatenate the integer part with the rounded decimal part
        return `${integerWithApostrophes}.${decimalPart}`;
    }

    convertToNumber(str) {
        // Remove apostrophe and convert to number
        return parseFloat(str.replace(/'/g, ''));
    }
}