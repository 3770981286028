import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "file", "button", "label", "description", "dropZone", "progressBar", "uploadedFiles", "fileUploadTemplate" ]

    onChange(event) {
        this.files = this.fileTarget.files;
        this.submit(event);
    }

    async submit(event) {
        event.preventDefault();
        const form = this.fileTarget.closest('form');

        // Extract non-file form fields into a plain object
        const formElements = form.elements;
        const otherFormData = {};
        for (let i = 0; i < formElements.length; i++) {
            let element = formElements[i];
            if (element.name && element.type !== 'file' && element.value !== "") {
                otherFormData[element.name] = element.value;
            }
        }

        // Loop through each file and submit them individually
        await Array.from(this.fileTarget.files).forEach((file, index) => {
            const formData = new FormData();

            // Append the non-file fields to each FormData instance
            for (let key in otherFormData) {
                formData.append(key, otherFormData[key]);
            }

            // Append the file to FormData instance
            const name = this.fileTarget.name.replace('[]', '');
            formData.append(name, file);


            let identifier = this.timestampString();
            formData.append('upload_start_time', document.getElementById('employee_upload_start_time').value)
            formData.append('identifier', identifier);

            const cloneDiv =  document.getElementById('file-upload-template').cloneNode(true);
            cloneDiv.classList.remove('hidden');
            cloneDiv.id = `uploaded-file-${identifier}`;
            let title = cloneDiv.querySelector('#uploaded-file-name');
            title.innerText = file.name;
            let displaySize;
            let isMB;

            const fileSizeInMB = file.size / (1024 * 1024); // Convert size to MB
            const fileSizeInKB = file.size / 1024; // Convert size to KB

            if (fileSizeInMB >= 1) {
                displaySize = `${fileSizeInMB.toFixed(1)} MB`;
                isMB = true;
            } else {
                displaySize = `${fileSizeInKB.toFixed(1)} KB`;
                isMB = false;
            }

            const mimeType = file.type;
            let iconElementId;
            if (mimeType.startsWith('image/')) {
                iconElementId = '#uploaded-image-icon';
            } else if (mimeType === 'application/zip' || mimeType === 'application/x-zip-compressed') {
                iconElementId = '#uploaded-zip-file-icon';
            } else {
                iconElementId = '#uploaded-file-icon';
            }

            cloneDiv.querySelector(iconElementId).classList.remove('hidden')

            document.getElementById('uploaded-files').appendChild(cloneDiv);
            this.uploadFile(formData, form.action, cloneDiv, isMB, displaySize);
        });
    }

    uploadFile(formData, action, cloneDiv, isMB, displaySize) {
        let description = cloneDiv.querySelector('#uploaded-file-description');
        const xhr = new XMLHttpRequest();
        xhr.open('POST', action, true);

        let progressBar = cloneDiv.querySelector('#uploaded-file-progress-bar');
        xhr.upload.addEventListener("progress", event => {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                progressBar.style.width = `${percentComplete}%`;

                let loadedSize;
                if (isMB) {
                    loadedSize = (event.loaded / (1024 * 1024)).toFixed(1); // Convert loaded size to MB
                } else {
                    loadedSize = (event.loaded / 1024).toFixed(1); // Convert loaded size to KB
                }

                if (loadedSize > displaySize) {
                    loadedSize = displaySize
                }

                description.innerText = `${percentComplete.toFixed(1)}% - ${loadedSize}/${displaySize}`
            }
        });

        xhr.onload = () => {
            if (xhr.status === 200) {
                Turbo.renderStreamMessage(xhr.responseText);
            }
        };

        xhr.setRequestHeader('X-CSRF-Token', document.querySelector('[name="csrf-token"]').content);
        xhr.setRequestHeader('Accept', 'text/vnd.turbo-stream.html'); // Ensure Turbo Stream response
        xhr.send(formData);
    }

    timestampString () {
        return new Date().getTime().toString()
    }

}
