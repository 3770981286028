import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["submitButton"]

  submitForm() {
    this.submitButtonTarget.click()
  }
}
