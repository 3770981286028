import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["container"];

    connect() {
        document.addEventListener("onModalBackdropClick", this.onClick.bind(this));
    }
    onClick(event) {
        event.preventDefault();

        const firstForm = document.querySelector("div[data-components--employee-zone--settings--processes--working-contracts--contract-templates--content-template--edit-target='container']").querySelector('form');
        if (!firstForm) return;

        let hiddenInput = document.getElementById('contract_template_is_draft')
        hiddenInput.value = true

        firstForm.requestSubmit()
    }

    disconnect() {
        document.removeEventListener("onModalBackdropClick", this.onClick.bind(this));
    }
}