import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'hiddenInputWrapper', 'buttonEnabled', 'buttonDisabled']
  
  connect() {
    this.updateButton();
  }

  toggle() {
    let input = this.hiddenInputWrapperTarget.querySelector('#tasks_query_my_tasks')
    input.checked = !input.checked
    input.form.querySelector("input[type='submit']").click()
  }

  updateButton() {
    if (this.hiddenInputWrapperTarget.querySelector('#tasks_query_my_tasks').checked) {
      this.buttonEnabledTarget.classList.add('hidden')
      this.buttonDisabledTarget.classList.remove('hidden')
    } else {
      this.buttonDisabledTarget.classList.add('hidden')
      this.buttonEnabledTarget.classList.remove('hidden')
    }
  }
  
}
