import { Controller } from "@hotwired/stimulus"
import { StreamChat } from '../../../../libraries/stream_chat/library';
import MustacheHelper from "../../../../helpers/mustache_helper";
import { DateTime } from 'luxon'
export default class extends Controller {
    static values = {
        streamChatApiKey: String,
        streamChatUserId: String,
        streamChatUserToken: String
    }

    async connect() {
        await this.initializeStreamChatClient()
        await this.reloadChannelList()

        this.streamChatClient.on(
            'notification.added_to_channel',
            event => this.reloadChannelList()
        )
    }

    async initializeStreamChatClient() {
        this.streamChatClient = StreamChat.getInstance(this.streamChatApiKeyValue);

        await this.streamChatClient.connectUser(
            { id: this.streamChatUserIdValue },
            this.streamChatUserTokenValue
        );
    }

    async reloadChannelList() {
        await this.fetchChannels();
        this.renderChannelList();
    }

    async fetchChannels() {
        this.channelEventListeners?.forEach(listener => listener.unsubscribe());
        this.channelEventListeners = [];

        const filter = { type: 'messaging', members: { $in: [this.streamChatUserIdValue] } };
        const sort = [{ last_message_at: -1 }];

        this.channels = await this.streamChatClient.queryChannels(filter, sort, {
            watch: true,
            state: true
        });

        this.channelEventListeners = this.channels.map(channel => {
            return channel.on('message.new', event => this.reloadChannelList())
        })
    }

    renderChannelList() {
        const channelListHtml = document.getElementsByClassName('channel-list')[0]
        channelListHtml.innerHTML = ''

        this.channels.map((channel) => {
            const messages = channel.state.messageSets[0].messages
            const mostRecentMessage = messages[messages.length - 1]

            let mostRecentMessageSentAt;

            if (!!mostRecentMessage) {
                const mostRecentMessageIsToday = this.messageSentAt(mostRecentMessage).hasSame(DateTime.now(), 'day')
                if (mostRecentMessageIsToday) {
                    mostRecentMessageSentAt = this.messageSentAt(mostRecentMessage).toLocaleString(DateTime.TIME_24_SIMPLE)
                } else {
                    mostRecentMessageSentAt = this.messageSentAt(mostRecentMessage).toFormat('dd.LL.y')
                }
            } else {
                mostRecentMessageSentAt = ''
            }

            channelListHtml.appendChild(
                MustacheHelper.toElement(
                    'components--employee-zone--chat--index--mustache-template--chat-list-item',
                    {
                        title: this.channelTitle(channel),
                        channelId: channel.id,
                        otherMemberIds: this.otherMemberIds(channel),
                        mostRecentMessage: mostRecentMessage?.text,
                        mostRecentMessageSentAt: mostRecentMessageSentAt
                    }
                )
            )
        })
    }

    otherMembers(channel) {
        return (({ [this.streamChatUserIdValue]: _, ...rest }) => rest)(channel.state.members);
    }

    otherMemberIds(channel) {
        return Object.keys(this.otherMembers(channel))
    }

    channelTitle(channel) {
        const otherMembers = this.otherMembers(channel)
        if (otherMembers === undefined) { return '' }
        return Object.values(otherMembers).map(member => member.user.name).join(', ')
    }

    messageSentAt(message) {
        let sentAt;

        switch (typeof message.created_at) {
            case 'object':
                sentAt = message.created_at.toISOString()
                break
            case 'string':
                sentAt = message.created_at
                break
        }

        return DateTime.fromISO(sentAt)
    }
}
