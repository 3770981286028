import { Controller } from "@hotwired/stimulus"
import MustacheHelper from "../../../../../helpers/mustache_helper"
import { DateTime } from 'luxon'

export default class extends Controller {
    static targets = [
        'channelTitle',
        'messageInput',
        'disabledSendButton',
        'enabledSendButton'
    ]

    static values = {
        streamChatChannelId: String,
    }

    connect() {
        this.setStreamChatClient()
        this.setChannel()
        this.renderChannelDetails()
        this.renderMessages()
        this.listenToAndRenderNewMessages()
        this.listenToMessageChanges()
        this.listenToSendMessageButton()
    }

    disconnect() {
        this.channelEventListener.unsubscribe()
    }

    setStreamChatClient() {
        this.setChatIndexController()
        this.streamChatClient = this.chatIndexController.streamChatClient
    }

    setChatIndexController() {
        const controllerName = 'components--employee-zone--chat--index'

        this.chatIndexController = this.application.getControllerForElementAndIdentifier(
            this.element.closest(`[data-controller="${controllerName}"]`),
            controllerName
        )
    }

    setChannel() {
        this.channel = this.chatIndexController.channels.find(channel => channel.id === this.streamChatChannelIdValue)
    }

    renderChannelDetails() {
        this.channelTitleTarget.innerText = this.chatIndexController.channelTitle(this.channel)

    }

    renderMessages() {
        const messages = this.channel.state.messageSets[0].messages
        messages.map((message) => {
            this.renderMessage(message)
        })
    }

    renderMessage(message) {
        document.getElementsByClassName('channel-details-messages')[0].appendChild(
            MustacheHelper.toElement(
                'components--employee-zone--chat--channel-details--index--mustache-template--message',
                {
                    senderName: message.user.name,
                    sentAt: this.chatIndexController.messageSentAt(message).toLocaleString(DateTime.TIME_24_SIMPLE),
                    message: message.html
                }
            )
        )
    }

    listenToAndRenderNewMessages() {
        this.channelEventListener = this.channel.on('message.new', event => {
            this.renderMessage(event.message)
        });
    }

    listenToMessageChanges() {
        this.messageInputTarget.addEventListener('keyup', (event) => {
            if (this.messageInputTarget.value.trim() === '') {
                this.disabledSendButtonTarget.classList.remove('hidden')
                this.enabledSendButtonTarget.classList.add('hidden')
            } else {
                this.disabledSendButtonTarget.classList.add('hidden')
                this.enabledSendButtonTarget.classList.remove('hidden')
            }
        })
    }

    listenToSendMessageButton() {
        this.enabledSendButtonTarget.addEventListener('click', () => {
            this.channel.sendMessage({ text: this.messageInputTarget.value })
            this.messageInputTarget.value = ''
            this.disabledSendButtonTarget.classList.remove('hidden')
            this.enabledSendButtonTarget.classList.add('hidden')
        })
    }
}
