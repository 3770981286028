import { Controller } from "@hotwired/stimulus"
import {get, put, post, destroy} from "@rails/request.js"

export default class extends Controller {
    static values = { contractTemplateId: Number, isArchived: Boolean }
    static targets = ["dots"]

    async onCardClick(event) {
        if (this.dotsTarget.contains(event.target)) {
            event.preventDefault();
            return;
        }

        await get(`/${window.currentWorkspaceSlug}/employee_zone/settings/processes/working_contracts/contract_templates/${this.contractTemplateIdValue}`, {
            responseKind: "turbo-stream"
        });
    }

    async duplicateContract() {
        await post(`/${window.currentWorkspaceSlug}/employee_zone/settings/processes/working_contracts/contract_templates`, {
            responseKind: "turbo-stream",
            body: { contract_template_id: this.contractTemplateIdValue}
        });
    }

    async updateContract(event, body) {
        event.preventDefault();
        await put(`/${window.currentWorkspaceSlug}/employee_zone/settings/processes/working_contracts/contract_templates/${this.contractTemplateIdValue}`, {
            responseKind: "turbo-stream",
            body: { contract_template: body }
        });
    }

    async activateContract(event) {
        const body = this.isArchivedValue
            ? { is_archived: false, active_state: 2 }
            : { active_state: 2 };
        await this.updateContract(event, body);
    }

    async deactivateContract(event) {
        await this.updateContract(event, { active_state: 1 });
    }

    async archiveContract(event) {
        await this.updateContract(event, { is_archived: true });
    }

    async unarchiveContract(event) {
        await this.updateContract(event, { is_archived: false });
    }

    async editDraft(event){
        event.preventDefault();
        await get(`/${window.currentWorkspaceSlug}/employee_zone/settings/processes/working_contracts/contract_templates/general/${this.contractTemplateIdValue}/edit`, {
            responseKind: "turbo-stream"
        });
    }

    async deleteDraft(event) {
        event.preventDefault();
        await destroy(`/${window.currentWorkspaceSlug}/employee_zone/settings/processes/working_contracts/contract_templates/${this.contractTemplateIdValue}`, {
            responseKind: "turbo-stream"
        });
    }
}