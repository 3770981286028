import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["showMoreButton", "showLessButton", "showMoreContent", "showLessContent"]

  connect() {
  }

  showMore() {
    this.showMoreButtonTarget.classList.add('hidden')
    this.showLessButtonTarget.classList.remove('hidden')
    this.showMoreContentTarget.classList.remove('hidden')
    if (this.hasShowLessContentTarget) {
      this.showLessContentTarget.classList.add('hidden')
    }
  }

  showLess() {
    this.showMoreButtonTarget.classList.remove('hidden')
    this.showLessButtonTarget.classList.add('hidden')
    this.showMoreContentTarget.classList.add('hidden')
    if (this.hasShowLessContentTarget) {
      this.showLessContentTarget.classList.remove('hidden')
    }
  }
}
