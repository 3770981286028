import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["scrollDiv"];
    connect() {
        this.scrollDivTarget.addEventListener('scroll', () => {
            const scrollEvent = new CustomEvent("divScrolled", {
                bubbles: true,
            });

            // Dispatch the event on the scrollableDivTarget so it bubbles up through the DOM
            this.scrollDivTarget.dispatchEvent(scrollEvent);
        });
    }
}
