import { StreamChat } from 'stream-chat';
import { MockStreamChat } from './mock'

let streamChatClient;

if (import.meta.env.MODE === 'test') {
    streamChatClient = MockStreamChat;
} else {
    streamChatClient = StreamChat
}

export { streamChatClient as StreamChat };
