import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        this.element.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
            checkbox.addEventListener("change", this.submitForm.bind(this))
        })
    }

    submitForm() {
        this.element.requestSubmit()
    }
}