import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { timetrackingStartedAt: String, stopwatchIntervalId: Number}
  static targets = ["hours", "minutes", "seconds", "playButton", "pauseButton", "timetrackingStartedAtFormInput", "timetrackingEndedAtFormInput", "submitButton"]
  connect() {
    if (!!this.timetrackingStartedAtValue) {
      this.stopwatchIntervalIdValue = setInterval(() => {
        this.updateDisplayedTime()
      }, 1000)
    }
  }

  toggleTimetracking() {
    if (!!this.timetrackingStartedAtValue) {
      this.endTimetracking()
    } else {
      this.startTimetracking()
    }
  }

  startTimetracking() {
    this.timetrackingStartedAtValue = new Date()
    this.stopwatchIntervalIdValue = setInterval(() => {
      this.updateDisplayedTime()
    }, 1000)

    this.playButtonTarget.classList.add("hidden")
    this.pauseButtonTarget.classList.remove("hidden")
    this.timetrackingStartedAtFormInputTarget.value = this.timetrackingStartedAtValue
    this.submitButtonTarget.click()
  }

  endTimetracking() {
    clearInterval(this.stopwatchIntervalIdValue);
    this.timetrackingStartedAtValue = "";

    this.playButtonTarget.classList.remove("hidden")
    this.pauseButtonTarget.classList.add("hidden")

    this.timetrackingEndedAtFormInputTarget.value = new Date()
    this.submitButtonTarget.click()
  }

  updateDisplayedTime() {
    let startTime = Date.parse(this.timetrackingStartedAtValue)

    let elapsedTimeSeconds = Math.floor((new Date() - startTime) / 1000)
    this.secondsTarget.innerText = String(elapsedTimeSeconds % 60).padStart(2, '0');
    this.minutesTarget.innerText = String(Math.floor(elapsedTimeSeconds / 60) % 60).padStart(2, '0');
    this.hoursTarget.innerText = String(Math.floor(elapsedTimeSeconds / 3600)).padStart(2, '0');
  }
}