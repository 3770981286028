import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mainSection", "nestedFieldsWrapper", "childrenIndex"];
  static values = { openTaskTemplateFormIdentifier: String }

  showTaskTemplateForm(event) {
    let identifier = event.currentTarget.dataset.identifier
    this.mainSectionTarget.classList.add("invisible")

    this.mainSectionTarget.childNodes.forEach((child) => {
      child.classList.add("hidden")
    })


    this.childrenIndexTargets.forEach((child) => {
      child.classList.add("hidden")
    })

    this.nestedFieldsWrapperTarget.classList.remove("hidden")

    this.openTaskTemplateFormIdentifierValue = identifier

    let taskForm = document.querySelector(`#employee-zone--work-schedule--shift-templates--new--task-template-wrapper-${identifier}`)
    taskForm.classList.remove("hidden")
  }


  closeTaskTemplateForm() {
    let taskForm = document.querySelector(`#employee-zone--work-schedule--shift-templates--new--task-template-wrapper-${this.openTaskTemplateFormIdentifierValue}`)
    this.mainSectionTarget.classList.remove("invisible")
    taskForm.classList.add("hidden")
    this.mainSectionTarget.childNodes.forEach((child) => {
      child.classList.remove("hidden")
    })

    this.childrenIndexTargets.forEach((child) => {
      child.classList.remove("hidden")
    })

    let taskTitle = document.querySelector(`#employee-zone--work-schedule--shift-templates--new--task-template-title-${this.openTaskTemplateFormIdentifierValue}`)
    let titleInput = document.querySelector(`#shift_template_task_templates_attributes_${this.openTaskTemplateFormIdentifierValue}_title`)
    
    taskTitle.textContent = titleInput.value
  }
}