import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        document.getElementById('employee_upload_start_time').value = this.timestampString()
    }

    timestampString () {
        return new Date().getTime().toString()
    }
}