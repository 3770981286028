import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "tabButtonList", "contentList" ]

    connect() {
        this.unselectTabButtons()
        this.hideContent()
        this.setSelectedTabButton(this.tabButtonListTarget.children[0])
        this.setSelectedContent(this.contentListTarget.children[0])
    }

    handleButtonClick(event) {
        let selectedElement
        for (let i = 0; i < this.tabButtonListTarget.children.length; i++) {
            if (this.tabButtonListTarget.children[i] === event.currentTarget) {
                selectedElement = this.contentListTarget.children[i]
            }
        }
        this.unselectTabButtons()
        this.setSelectedTabButton(event.currentTarget)
        this.hideContent()
        this.setSelectedContent(selectedElement)
    }

    setSelectedTabButton(element) {
        element.classList.remove('hover:bg-canvas-darker', 'font-normal')
        element.classList.add('text-canvas-white', 'bg-primary', 'hover:bg-primary-hover', 'font-bold')
    }

    unselectTabButtons () {
        let element
        for (let i = 0; i < this.tabButtonListTarget.children.length; i++) {
            element = this.tabButtonListTarget.children[i]
            element.classList.remove('text-canvas-white', 'bg-primary', 'hover:bg-primary-hover', 'font-bold')
            element.classList.add('hover:bg-canvas-darker', 'font-normal')
        }
    }

    setSelectedContent(element) {
        element.classList.remove('hidden')
    }

    hideContent() {
        let element
        for (let i = 0; i < this.contentListTarget.children.length; i++) {
            element = this.contentListTarget.children[i]
            element.classList.add('hidden')
        }
    }
}
