import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { isEnabled: Boolean }
    static targets = ["inputContainer"];

    connect() {
        if (!this.isEnabledValue) {
            this.onClick()
        }
    }

    onClick(){
        // Query all input, textarea, and select elements within the controller's scope
        const formFields = this.inputContainerTarget.querySelectorAll('input, textarea');

        formFields.forEach(field => {
            field.disabled = !field.disabled; // Toggle the disabled state

            // Toggle a style class or directly change the style
            if (field.disabled) {
                field.classList.add('bg-white', 'border-transparent', '!important');
            } else {
                field.classList.remove('bg-white', 'border-transparent', '!important');
            }
        });
    }
}