import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "file", "button", "label", "description", "dropZone" ]

    connect() {
        this.setTitle(this.dropZoneTarget.getAttribute('data-file-upload-title'))
        this.setDescription(this.dropZoneTarget.getAttribute('data-max-file-size'), "Up to")
    }

    onChange(){
        this.files = this.fileTarget.files
        this.updateView(this.files)
    }

    dragOver(event){
        event.preventDefault()
        this.dropZoneTarget.classList.add('border-2', 'border-dashed', 'border-inline', 'cursor-pointer')
    }

    dragLeave(){
        this.dropZoneTarget.classList.remove('border-2', 'border-dashed', 'border-inline', 'cursor-pointer')
    }

    drop(event){
        event.preventDefault()

        this.dragLeave()
        const files = event.dataTransfer.files
        this.updateView(files)
    }

    updateView(files){
        this.updateButton(this.dropZoneTarget.getAttribute('data-selected-styles'))
        this.setTitle(files[0].name)
        this.setDescription(files[0].size)
        this.dispatch('updateView')
    }


    updateButton(selectedStyles) {
        if(selectedStyles) {
            selectedStyles = selectedStyles.split(' ')
            for (let i = 0; i < selectedStyles.length; i++) {
                this.buttonTarget.classList.add(selectedStyles[i])
            }
        }
    }

    setTitle(title){
        if (title === '') {
            title = 'File'
        }
        this.labelTarget.textContent = title;
    }

    setDescription(fileSizeInBits, customDescription = ''){
        if (fileSizeInBits === 0 || fileSizeInBits === '') {
            fileSizeInBits = 20971520 // in Bits is the same Format as we get it from the File itself
        }

        let fileSize;
        let unit;
        let fileSizeInKb = Math.ceil(fileSizeInBits / 1024); // in Kb

        if (fileSizeInKb >= 1024 * 1024) {
            fileSize = fileSizeInKb / (1024 * 1024) // in Gb with 1 decimal place
            unit = 'Gb'
        } else if (fileSizeInKb >= 1024) {
            fileSize = fileSizeInKb / 1024 // in Mb with 1 decimal place
            unit = 'Mb'
        } else {
            fileSize = fileSizeInKb
            unit = 'Kb'
        }

        fileSize = fileSize.toFixed(1).toString().replace(/(\.\d*?)0$/, '$1').replace(/\.$/, '')
        this.descriptionTarget.textContent = `${customDescription} ${fileSize} ${unit}`;
    }


}
