import { Controller } from "@hotwired/stimulus"
import {get} from '@rails/request.js'

export default class extends Controller {
    static values = {workspaceSlug: String, employeeId: Number, contractId: Number }

    connect() {
        document.addEventListener("itemSelected", this.handleSelect.bind(this));
    }

    async handleSelect(event) {
        let dropdownContentId = event.detail.event.currentTarget.closest('[data-components--form--select-dropdown-target="itemContainer"]').id
        dropdownContentId = dropdownContentId.replace('active-dropdown-', '');
        dropdownContentId = dropdownContentId.replaceAll('-', '_');

        let hiddenSelectInput = document.getElementById(dropdownContentId)
        let nestedFormWrapper = hiddenSelectInput.closest('.nested-form-wrapper')
        let descriptionDiv = nestedFormWrapper.querySelector('.special-condition-description')

        let descriptionDivIdentifier = this.timestampString()
        descriptionDiv.id = `special-conditions-${descriptionDivIdentifier}`

        let idSplitUp = event.detail.event.currentTarget.id.split('_')
        let specialConditionId = idSplitUp[idSplitUp.length - 1]
        await get(`/${this.workspaceSlugValue}/employee_zone/employees/${this.employeeIdValue}/contracts/conditions/special_condition/${this.contractIdValue}/edit?special_condition_id=${specialConditionId}&special_description_id=${descriptionDivIdentifier}`,  { responseKind: "turbo-stream" })
    }

    timestampString () {
        return new Date().getTime().toString()
    }
}