import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["formContainer, unauthorisedContainer"];
  static outlets = ["components--element--modal"]

  connect() {
    this.closeChecklistForm()
  }

  validateEmployees() {
    let employee_ids = Array.from(document.querySelectorAll('[name="calendar_entry[employee_ids][]"]:checked')).map(c => c.value);
    let location_ids = Array.from(document.querySelectorAll('[name="calendar_entry[location_ids][]"]:checked')).map(c => c.value);
    let department_ids = Array.from(document.querySelectorAll('[name="calendar_entry[department_ids][]"]:checked')).map(c => c.value);

    fetch(`/${window.currentWorkspaceSlug}/employee_zone/tasks/validate_task_employee_assignments`, {
      method: 'post',
      body: JSON.stringify({
        employee_ids: employee_ids,
        location_ids: location_ids,
        department_ids: department_ids
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content
      }
    }).then((response) => {
      return response.json()
    }).then((res) => {
      let deselect_ids = res["unauthorised_employees"]
      let employee_names = res["names"]

      if (deselect_ids.length == 0) {
        this.submitForm();
        return;
      } else {
        this.componentsElementModalOutlet.toggleModal();
        document.querySelector("#remove-members-btn").data = deselect_ids
        document.querySelector("#employee-zone--tasks--task--new-form--unauthorized-employees").textContent += (" " + employee_names.join(", "));
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  removeEmployees() {
    let deselect_ids = document.querySelector("#remove-members-btn").data
    let selectors = deselect_ids.map(n => `#calendar_entry_employee_ids_${n}`);
    let elements = selectors.flatMap(selector => Array.from(document.querySelectorAll(selector)));
    elements.forEach(element => {
      element.checked = false;
    });
    this.componentsElementModalOutlet.toggleModal();
    this.submitForm();
  }

  submitForm() {
    document.querySelector("#employee-zone--tasks--task--new-form--submit-button").click();
  }


  resetChecklistForm() {
    document.querySelector("#calendar_entry_checklist_title").value = ""
    document.querySelectorAll(".remove-checklist-item-button").forEach((button) => { button.click() })
    document.querySelector("#components--employee-zone--tasks--new-checklist-form-back-button").click()
  }

  resetFormForm() {
    document.querySelector("#calendar_entry_form_title").value = ""
    document.querySelectorAll(".remove-form-question-button").forEach((button) => { button.click() })
    document.querySelector("#components--employee-zone--tasks--new-form-form-back-button").click()
  }

  closeChecklistForm() {
    const element = document.querySelector("#components--employee-zone--tasks--new-checklist-form-open-button")
    const labelChecklistEmpty = element.dataset.labelChecklistEmpty
    const labelChecklistExists = element.dataset.labelChecklistExists
    const buttonLabel = document.querySelector("#components--employee-zone--tasks--new--add-checklist-item-button div").lastChild

    if (document.querySelector("#calendar_entry_checklist_title").value == "") {
      buttonLabel.textContent = labelChecklistEmpty
    } else {
      buttonLabel.textContent = labelChecklistExists
    }
  }

  closeFormForm() {
    const element = document.querySelector("#components--employee-zone--tasks--new-form-form-open-button")
    const labelFormEmpty = element.dataset.labelFormEmpty
    const labelFormExists = element.dataset.labelFormExists
    const buttonLabel = document.querySelector("#components--employee-zone--tasks--new--add-form-question-button div").lastChild

    if (document.querySelector("#calendar_entry_form_title").value == "") {
      buttonLabel.textContent = labelFormEmpty
    } else {
      buttonLabel.textContent = labelFormExists
    }
  }

}
