import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["form"]
  
  submitForm(){
    this.formTarget.querySelector("input[type='submit']").click();
  }

}
