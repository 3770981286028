import { Controller } from "@hotwired/stimulus"
import {put} from "@rails/request.js"

export default class extends Controller {
    connect() {
        document.addEventListener("dateChanged", this.onChange.bind(this));
    }

    async onChange() {
        let hiddenInputElement = event.detail.event.currentTarget.closest('.uploaded-item-wrapper').querySelector('input[type="hidden"]')
        let documentId = this.extractIdFromName(hiddenInputElement.name)
        let expiryDateElement = event.detail.event.currentTarget.closest('.uploaded-item-wrapper').querySelector('#employee_expiry_date')
        await put(`documents/${documentId}`, {
            responseKind: 'turbo-stream',
            body: {
                document: {
                    expiry_date: expiryDateElement.value,
                }
            }
        })
    }

    extractIdFromName(name) {
        // Regular expression to match the id within the brackets
        const match = name.match(/\[documents_attributes\]\[(\d+)\]\[id\]/);
        return match ? match[1] : null;
    }
}