import { Controller } from "@hotwired/stimulus"
import { enter } from "el-transition";

export default class extends Controller {
    static targets = [ "snackBarContainer", "snackBar", "loadingBar" ]

    connect() {
        this.animateLoadingBar(5 * 1000)
        this.showSnackBar();
    }

    showSnackBar() {
        this.snackBarTarget.classList.remove("hidden");
        enter(this.snackBarTarget);
        const disappearAfterSeconds = this.snackBarContainerTarget.getAttribute('data-disappear-after-seconds');

        if (Boolean(disappearAfterSeconds)) {
            // Start the loading bar animation
            this.animateLoadingBar(disappearAfterSeconds, () => {
                this.hideSnackBar();
            });
        }
    }

    animateLoadingBar(duration, onComplete) {
        const loadingBar = this.loadingBarTarget;
        let width = 100;
        const intervalTime = 10; // Interval time in milliseconds
        const decrementAmount = 100 / (duration * 1000 / intervalTime); // Calculate decrement amount based on duration

        const interval = setInterval(() => {
            width -= decrementAmount;
            loadingBar.style.width = `${width}%`;
            if (width <= 0) {
                clearInterval(interval);
                if (typeof onComplete === 'function') {
                    onComplete(); // Call the onComplete callback function
                }
            }
        }, intervalTime);
    }

    hideSnackBar() {
        this.snackBarTarget.classList.add("hidden");
    }
}
