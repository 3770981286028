import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password-visibility"
export default class extends Controller {
    static targets = ["input", "showPasswordIcon", "hiddenPasswordIcon"]

    toggleInput() {
        const type = this.inputTarget.type === "password" ? "text" : "password"
        this.inputTarget.type = type
        if (this.inputTarget.type === 'password') {
            this.showPasswordIconTarget.classList.remove('hidden')
            this.hiddenPasswordIconTarget.classList.add('hidden')
        } else {
            this.showPasswordIconTarget.classList.add('hidden')
            this.hiddenPasswordIconTarget.classList.remove('hidden')
        }
    }
}