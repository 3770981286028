import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modalWrapper"];

  connect() {
    this.modalWrapperTarget.addEventListener('click', function (e) {
      e.stopPropagation();
    });
  }

  handleClick(event) {
    

    if (event.shiftKey) {
      //this will be used later for the bulk actions
    } else {
      let modalComponent = this.modalWrapperTarget.querySelector("[data-controller='components--element--modal']")

      this.application.getControllerForElementAndIdentifier(modalComponent, "components--element--modal").toggleModal()
    }
  }
}