import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['container']
    static values = { workspaceSlug:String, employeeId:Number, contractId:Number, editUrl:String, step:Number }

    async onClick(event) {
        event.preventDefault();

        const firstForm = this.containerTarget.querySelector('form');
        if (!firstForm) return;

        let hiddenInput = document.getElementById('contract_is_preview')
        hiddenInput.value = true

        firstForm.requestSubmit()
    }
}