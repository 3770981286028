import { Controller } from "@hotwired/stimulus"
import IMask from 'imask'

export default class extends Controller {
    static targets = [ 'input', 'hiddenInput' ]

    connect() {
        this.inputTarget.value = this.hiddenInputTarget.value // this might cause problems with system tests
            this.maskOptions = {
                mask: Number,
                thousandsSeparator: "'",
                scale: 2,
                radix: ".",
            };

            this.mask = IMask(this.inputTarget, this.maskOptions);
    }

    onInput(event) {
        this.hiddenInputTarget.value = this.parseAndConvertNumber(IMask(this.inputTarget, this.maskOptions))
    }

    parseAndConvertNumber(element) {
        let number = element.value.replace(/'/g, '')
        return !isNaN(Number(number)) ? Number(number) : 0
    }

    disconnect() {
        if (this.mask) {
            this.mask.destroy()
        }
    }
}
