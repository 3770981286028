import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition";

export default class extends Controller {
    static targets = [ "menuMobile", "menuBackdrop", "menu", "closeButton" ]

    showSidebar() {
        this.menuMobileTarget.classList.remove("hidden");
        enter(this.menuBackdropTarget);
        enter(this.menuTarget);
        enter(this.closeButtonTarget);
    }

    hideSidebar() {
        Promise.all([
            leave(this.menuBackdropTarget),
            leave(this.menuTarget),
            leave(this.closeButtonTarget),
        ]).then(() => {
            this.menuMobileTarget.classList.add("hidden");
        });
    }
}
