import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["checkbox", "masterCheckbox"];

    connect() {
        this.syncCheckboxes()
    }

    toggleMaster(event) {
        const checked = event.target.checked;
        const permission = event.target.dataset.permission;
        this.checkboxTargets.forEach(checkboxContainer => {
            const checkbox = checkboxContainer.querySelector('input[type="checkbox"]');
            const checkboxPermission = checkbox.dataset.permission;
            if (checkboxPermission === permission) {
                checkbox.checked = checked;
            }
        });
    }

    toggle(event) {
        const permission = event.target.dataset.permission
        const allChecked = this.checkboxTargets.every(checkboxContainer => {
            const checkbox = checkboxContainer.querySelector('input[type="checkbox"]');
            const result = checkbox.dataset.permission !== permission || checkbox.checked;
            return result
        });
        this.masterCheckboxTargets.forEach(masterCheckbox => {
            const checkbox = masterCheckbox.querySelector('input[type="checkbox"]');
            if (checkbox.dataset.permission === permission) {
                checkbox.checked = allChecked;
            }
        });
    }

    syncCheckboxes() {
        this.masterCheckboxTargets.forEach(masterCheckbox => {
            const masterBox = masterCheckbox.querySelector('input[type="checkbox"]');
            const permission = masterBox.dataset.permission;
            const allChecked = this.checkboxTargets.every(checkboxContainer => {
                const checkbox = checkboxContainer.querySelector('input[type="checkbox"]');
                return checkbox.dataset.permission !== permission || checkbox.checked;
            });
            masterBox.checked = allChecked;
        });
    }
}
