import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "tabBarHeaderItem" ]
    static values = { turboFrameId: String }

    connect() {
        this.setActiveTab({ target: this.tabBarHeaderItemTargets[0]})
    }
    setActiveTab(event) {
        const element = event.target
        element.classList.remove('hover:bg-canvas-darker', 'font-normal')
        element.classList.add('text-canvas-white', 'bg-primary', 'hover:bg-primary-hover', 'font-bold')

        this.tabBarHeaderItemTargets.forEach((selectedElement) => {
            if (selectedElement !== element) {
                selectedElement.classList.remove('text-canvas-white', 'bg-primary', 'hover:bg-primary-hover', 'font-bold')
                selectedElement.classList.add('hover:bg-canvas-darker', 'font-normal')
            }
        });

        document.getElementById(this.turboFrameIdValue).src = element.dataset.turboFrameSourcePath
    }
}
